import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import React from 'react';

const Footer = ({ companyName }) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulLayoutFooter {
        id
        logo {
          svg {
            content
          }
        }
        columns {
          title
          page {
            ... on ContentfulLayout {
              id
              url: path
              title
              index
            }
          }
        }
      }
    }
  `);

  const columnLayout = data.contentfulLayoutFooter.columns.map((column) => {
    const menuLinks = column.page.map((page) => {
      if (page.index) {
        return (
          <Link href="/" className="pb-2 text-gray-400">
            Home
          </Link>
        );
      } else if (page.url) {
        const prefix =
          page.url.startsWith('http') || page.url.startsWith('/') ? '' : '/';
        return (
          <Link href={`${prefix}${page.url}`} className="pb-2 text-gray-400">
            {page.title}
          </Link>
        );
      }
    });

    return (
      <div className="flex flex-col">
        <div className="font-medium mb-4">{column.title}</div>
        {menuLinks}
      </div>
    );
  });

  return (
    <footer className="container">
      <div className="grid-layout">
        {columnLayout}
        <div className="hidden md:block"></div>
        <div className="hidden md:block"></div>
        <div
          className="logo-wrapper hidden md:block"
          dangerouslySetInnerHTML={{
            __html: data.contentfulLayoutFooter.logo.svg.content,
          }}
        />
      </div>
      <hr />
      <div className="grid grid-flow-row grid-cols-2 md:grid-cols-4 gap-6 w-full md:w-auto py-6">
        <div className="text-gray-400 text-center md:text-left">
          © {new Date().getFullYear()} Arise Technology Inc.
        </div>
        <div className="text-gray-400 text-center md:text-left">
          San Francisco - Paris
        </div>
        <Link
          href="/privacy-policy"
          className="text-center md:text-right text-gray-400"
        >
          Privacy Policy
        </Link>
        <Link
          href="/terms-of-use"
          className="text-center md:text-right text-gray-400"
        >
          Terms of Use
        </Link>
      </div>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/4010440.js"
      ></script>
    </footer>
  );
};

export default Footer;
