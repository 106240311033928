import { Link, useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function Header({ siteTitle, primaryColor }) {
  const [isExpanded, toggleExpansion] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      contentfulLayoutMenu(contentful_id: { eq: "4w5vUjUitAk3QOin0s2y3n" }) {
        logo {
          svg {
            content
          }
        }
        menuItems {
          __typename
          ... on ContentfulLayout {
            id
            index
            path
            title
          }
        }
      }
    }
  `);

  const menuItems = data.contentfulLayoutMenu.menuItems.map((menuItem) => {
    if (menuItem.index) {
      return null;
    }

    switch (menuItem.__typename) {
      case 'ContentfulLayout':
        return (
          <Link
            className="menu-item"
            key={menuItem.title}
            to={`/${menuItem.path}`}
          >
            {menuItem.title}
          </Link>
        );

      case 'ContentfulExternalLink':
        return (
          <a className="menu-item" key={menuItem.title} href={menuItem.url}>
            {menuItem.title}
          </a>
        );

      default:
        return null;
    }
  });

  return (
    <nav className={`header-menu w-full mt-0 bg-white bg-${primaryColor}`}>
      <div className="container flex flex-row items-center justify-between mx-auto">
        <a href="/">
          <div
            className="py-6 logo-wrapper"
            dangerouslySetInnerHTML={{
              __html: data.contentfulLayoutMenu.logo.svg.content,
            }}
          />
        </a>
        <div
          className={`${
            isExpanded ? `hidden` : `hidden`
          } sm:hidden md:hidden lg:flex text-lg flex-row`}
        >
          {menuItems}
        </div>
        <div className="block lg:hidden">
          {/* beginning of hamburger */}
          <button
            onClick={() => toggleExpansion(!isExpanded)}
            className="flex items-center px-3 py-2 border rounded text-primary border-primary hover:text-secondary hover:border-secondary"
          >
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Hamburger Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
          {/* beginning of small navbar */}
          <div
            className={`${
              isExpanded ? `block` : `hidden`
            } lg:hidden text-lg relative float-right inline-block text-left`}
          >
            <div className="absolute right-0 w-48 mt-2 origin-top-right rounded-md shadow-lg">
              <div className="bg-white rounded-md shadow-xs">
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {menuItems}
                </div>
              </div>
            </div>
          </div>
          {/* end */}
        </div>
      </div>
    </nav>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
